// src/components/Home.js
import React from 'react';

export default function Home() {
  return (
    <div className="welcome">
      <h1>Welcome to our page!</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Erat imperdiet sed euismod nisi porta lorem mollis aliquam ut.
        Dignissim sodales ut eu sem integer vitae justo.
        Sapien pellentesque habitant morbi tristique senectus et.
        Consequat semper viverra nam libero justo laoreet sit amet cursus.
        Morbi tempus iaculis urna id volutpat lacus laoreet non curabitur.
        Diam in arcu cursus euismod quis viverra nibh cras.
        Commodo elit at imperdiet dui accumsan sit amet nulla facilisi.
        Habitant morbi tristique senectus et.
        Orci a scelerisque purus semper eget duis. Sed egestas egestas
        fringilla phasellus faucibus scelerisque eleifend donec pretium.
        Cursus mattis molestie a iaculis at erat pellentesque.
      </p>
      <p>
        Orci dapibus ultrices in iaculis nunc sed augue lacus viverra.
        Ac placerat vestibulum lectus mauris ultrices eros. Massa tincidunt
        nunc pulvinar sapien et ligula ullamcorper malesuada.
        Nisi quis eleifend quam adipiscing vitae proin. Sodales ut eu sem
        integer vitae justo eget magna.
        Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque.
        A condimentum vitae sapien pellentesque. Volutpat blandit aliquam etiam erat velit.
        Molestie a iaculis at erat pellentesque adipiscing commodo elit.
        Aliquam sem et tortor consequat. Facilisi nullam vehicula ipsum a arcu cursus vitae.
        Augue neque gravida in fermentum et sollicitudin ac.
        Varius duis at consectetur lorem donec massa sapien faucibus.
      </p>
    </div>
  );
}
